import React, {useEffect} from 'react';
import CircularProgress from "components/CircularProgress/index";
import Auxiliary from "util/Auxiliary";
import {useDispatch, useSelector} from "react-redux";
import {hideMessage} from "appRedux/actions/Common";

const InfoView = () => {
  const dispatch = useDispatch();
  const error = useSelector(({common}) => common.error);
  const loading = useSelector(({common}) => common.loading);
  const message = useSelector(({common}) => common.message);

  useEffect(() => {
    if (error || message) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 6000);
    }
  }, [error, message, dispatch]);


  return (
    <Auxiliary>
      {loading && <div className="gx-loader-view gx-loader-position">
        <CircularProgress/>
      </div>}
      {(error) && (<span id="message-id" className="ant-form-item-explain ant-form-item-explain-error">{error}</span>)}
      {(message.error) && (<span id="message-id" className="ant-form-item-explain ant-form-item-explain-error">{message.error}</span>)}

      {(message) && (<span id="message-id">{message}</span>)}
      {(message.info) && (<span id="message-id">{message.info}</span>)}
    </Auxiliary>
  );
};

export default InfoView;
