import React, {useEffect} from "react";
import {Button, Form, Input} from "antd";
import {useDispatch, useSelector} from "react-redux";
//import {useParams} from "react-router-dom";
import {userOptOut} from "../appRedux/actions/Auth";
//import {userOptOutExec} from "../appRedux/actions/Auth";
import IntlMessages from "../util/IntlMessages";
import InfoView from "../components/InfoView";
import logotype from   "./../assets/images/logo.png";

const OptOut = (props) => {
  const dispatch = useDispatch();
  const step = useSelector(({auth}) => auth.opt_out_step);
  //const {token} = useParams();

  const onFinish = values => {
    dispatch(userOptOut(values));
  };

  const onFinishFailed = errorInfo => { };

  useEffect(() => {
    //if(token !== undefined){
    //  dispatch(userOptOutExec(token));
    //}
  }, []);

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg"></div>
            <div className="gx-app-logo-wid">
              <h1><IntlMessages id="app.userAuth.bySigning"/></h1>
            </div>
            <div className="gx-app-logo">
              <img alt="example" src={logotype}/>
            </div>
          </div>

            <div className="gx-app-login-content">
              {
                step === 0 &&
                <Form
                  name="basic"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  className="gx-signin-form gx-form-row0">
                  <p>Jeżeli chcesz wypisać się z systemu, podaj swój adres e-mail. Prześlemy na niego link dzięki któremu będziesz mógł usunąć swoje dane z naszego systemu.</p>

                  <Form.Item
                    initialValue=""
                    rules={[{required: true, message: <IntlMessages id="validation.required"/>}]} name="email">
                    <Input placeholder="Adres e-mail"/>
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" className="gx-mb-0" htmlType="submit">
                      Tak, chcę wypisać się z systemu
                    </Button>
                  </Form.Item>
                </Form>
              }
              {
                step === 1 &&
                <Form
                  name="basic"
                  className="gx-signin-form gx-form-row0">
                  <h2>Sprawdź swoją skrzynkę pocztową. Znajdziesz tam dalsze instrukcje wypisania się z systemu.</h2>
                </Form>
              }
              {
                step === 2 &&
                <Form
                  name="basic"
                  className="gx-signin-form gx-form-row0">
                  <h2>Dziękujemy, Twoje konto zostało usunięte z naszego systemu.</h2>
                </Form>
              }
              {
                step === 3 &&
                <Form
                  name="basic"
                  className="gx-signin-form gx-form-row0">
                  <h2>Twój link wygasł. Spróbuj usunąć konto od nowa.</h2>
                </Form>
              }
              <InfoView />
            </div>
        </div>
      </div>
    </div>
  );
};

export default OptOut;
