import React, {useContext} from "react";
import {ReactReduxContext, useSelector} from 'react-redux'
import InlineNotify from "../components/_develtio/InlineNotify/InlineNotify";

const NotificationWrapper = (props) => {
  const {store} = useContext(ReactReduxContext);
  const notification = useSelector(({common}) => common.notification);

  return (
    <>
      {notification.type
      && <InlineNotify
        type={notification.type}
        message={notification.message}
        timeout={notification.timeout}
        store={store}/>}
      {props.children}
    </>
  )
};

export default NotificationWrapper;
