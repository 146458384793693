import axios from 'axios';

let axiosHeaders = {
  'Content-Type': 'application/json',
};

if (localStorage.getItem('token')) {
  axiosHeaders.Authorization = "Bearer " + JSON.parse(localStorage.getItem('token'))
}

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: axiosHeaders
});

api.interceptors.response.use((response) => response, (error) => {
  const statusCode = error.response ? error.response.status : 0;
  const url = error.response ? error.response.config.url : '';

  if (statusCode === 401 && url.indexOf('two-factor') === -1) {
    localStorage.removeItem("token");
    document.location.href = "/";
  }
  throw error;
});


export default api;


export function composeResponse(response) {
  if (response.status === 201 && !response.data.errors) {
    return true;
  }

  if (response.status === 200 && !response.data.errors) {
    return true;
  }

  if (response.data.errors) {
    return response.data
  }
}

