import axios from 'util/Api'
import ChangeLogCollection from "./collection";
import ChangeLogSchema from "./schema";
import {fetchErrorMessage} from "../../appRedux/actions";

export default async function fetchChangeLogs(store, payload) {
  return await axios
    .get(
      ChangeLogSchema.apiEndpoint,
      {params: payload.getPayload()}
    )
    .then(({data}) => {
      const collection = new ChangeLogCollection(data);
      return {
        objects: collection.getItems(),
        totalItems: collection.getTotalItems(),
        totalPages: collection.getTotalPages()
      };
    }).catch(function (error) {
      store.dispatch(fetchErrorMessage(error));
    });
};

export async function fetchChangeLog(store, payload) {
  return await axios
    .get(
      ChangeLogSchema.apiEndpoint,
      {params: payload.getPayload()}
    )
    .then(({data}) => {
      const collection = new ChangeLogCollection(data);
      const objects = collection.getItems();
      return {
        object: objects[0]
      };
    }).catch(function (error) {
      store.dispatch(fetchErrorMessage(error));
    });
}
