import {
  CLEAR_NOTIFICATION,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  SHOW_NOTIFICATION,
  SIGNIN_TWO_FACTOR
} from "../../constants/ActionTypes";

export const fetchStart = () => {
  return {
    type: FETCH_START
  }
};

export const fetchSuccess = () => {
  return {
    type: FETCH_SUCCESS
  }
};

export const fetchError = (error) => {
  return {
    type: FETCH_ERROR,
    payload: error
  }
};

export const showMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  }
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE
  }
};

export const signInTwoFactor = (two_factor_step, payload) => {
  return {
    type: SIGNIN_TWO_FACTOR,
    two_factor_step: two_factor_step,
    payload: payload
  }
};

export const fetchErrorMessage = (error) => {
  return {
    type: FETCH_ERROR,
    payload: "Wystąpił błąd ! - " + error
  };
};

export const showNotification = (mode, message, timeout = 3200) => {
  return {
    type: SHOW_NOTIFICATION,
    message: message,
    mode: mode,
    timeout: timeout
  };
};

export const clearNotification = () => {
  return {
    type: CLEAR_NOTIFICATION
  };
};
