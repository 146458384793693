import React from "react";

const NotificationItem = ({notification}) => {
  const {createdAt, integration, type, status} = notification.getObject();
  return (

    <li className="gx-media">
      <div className="gx-media-body gx-align-self-center">
        <p className="gx-fs-sm gx-mb-0">{integration} - {type} - {status}</p>
        <span className="gx-meta-date"><small>{createdAt}</small></span>
      </div>
    </li>
  );
};

export default NotificationItem;
