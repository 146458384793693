import antdPL from "antd/lib/locale-provider/pl_PL";
import saMessages from "../locales/pl_PL.json";

const saLang = {
  messages: {
    ...saMessages
  },
  antd: antdPL,
  locale: 'pl',
};
export default saLang;
