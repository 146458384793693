import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  SIGNIN_TWO_FACTOR,
  USER_TOKEN_SET, SHOW_MESSAGE, OPT_OUT_STEP
} from "../../constants/ActionTypes";
import axios from 'util/Api'
import React from "react";
import IntlMessages from "util/IntlMessages";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const userSignIn = ({email, password}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('two-factor/login', {
        email: email,
        password: password,
      }
    ).then(({data}) => {
      dispatch({type: FETCH_SUCCESS});

      dispatch({
        type: SIGNIN_TWO_FACTOR,
        two_factor_step: 1
      });

    }).catch(function (error) {
      dispatch({
        type: FETCH_ERROR,
        payload: <IntlMessages id="validation.invalidFields"/>
      });
    });
  }
};

export const userVerify = (email, token) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    axios
      .post('two-factor/verify', {
        token: token,
        email: email,
      })
      .then(({data}) => {
        dispatch({type: FETCH_SUCCESS});
        if (data.data) {
          localStorage.setItem("token", JSON.stringify(data.data.token));
          axios.defaults.headers.common['authorization'] = "Bearer " + data.data.token;
          dispatch({type: USER_TOKEN_SET, payload: data.data.token});
          dispatch(getUser());
          dispatch({type: FETCH_SUCCESS});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id="validation.wrongVerificationCode"/>
          });
        }
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_ERROR,
          payload: <IntlMessages id="validation.wrongVerificationCode"/>
        });
      });
  }
};

export const getUser = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios
      .get('api/user',)
      .then(({data}) => {
        if (data.code === 200) {
          localStorage.setItem("user", JSON.stringify(data.data));
          dispatch({type: USER_DATA, payload: data.data});
          dispatch({type: FETCH_SUCCESS});

        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: <IntlMessages id="validation.networkError"/>
          });
        }
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_ERROR,
          payload: <IntlMessages id="validation.networkError"/>
        });
      });
  }
};


export const userSignOut = () => {
  return (dispatch) => {
    localStorage.removeItem("token");
    dispatch({type: SIGNOUT_USER_SUCCESS});
    dispatch({
      type: SIGNIN_TWO_FACTOR,
      two_factor_step: 0
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: "Wylogowano pomyślnie!"
    })
  }
};


export const userOptOut = ({email}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('two-factor/login', { // TODO - set valid endpoint
        email: email,
      }
    ).then(({data}) => {
      dispatch({type: FETCH_SUCCESS});

      dispatch({
        type: OPT_OUT_STEP,
        opt_out_step: 1
      });
    }).catch(function (error) {
      dispatch({
        type: FETCH_ERROR,
        payload: <IntlMessages id="validation.invalidFields"/>
      });
    });
  }
};


export const userOptOutExec = (token) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('two-factor/login', { // TODO - set valid endpoint
        token: token,
      }
    ).then(({data}) => {
      dispatch({type: FETCH_SUCCESS});

      dispatch({
        type: OPT_OUT_STEP,
        opt_out_step: 2
      });
    }).catch(function (error) {
      dispatch({
        type: OPT_OUT_STEP,
        opt_out_step: 3
      });
    });
  }
};
