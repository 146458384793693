import React, {useEffect, useState} from "react";
import {Button, Form, Input} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {userSignIn, userVerify} from "../appRedux/actions/Auth";
import IntlMessages from "../util/IntlMessages";
import InfoView from "../components/InfoView";
import logotype from   "./../assets/images/logo.png";

const SignIn = (props) => {
  const dispatch = useDispatch();
  const token = useSelector(({auth}) => auth.token);
  const [email, setEmail] = useState(null);
  const step = useSelector(({auth}) => auth.two_factor_step);

  const onFinish = values => {
    setEmail(values.email);
    dispatch(userSignIn(values));
  };

  const onFinishTwoFactor = values => {
    dispatch(userVerify(email, values.token))
  };

  const onFinishFailed = errorInfo => { };
  const onFinishTwoFactorFailed = errorInfo => {};

  useEffect(() => {
    if (token !== null) {
      props.history.push('/');
    }
  }, [token, props.history]);

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg"></div>
            <div className="gx-app-logo-wid">
              <h1><IntlMessages id="app.userAuth.signIn"/></h1>
              <p><IntlMessages id="app.userAuth.bySigning"/></p>
            </div>
            <div className="gx-app-logo">
              <img alt="example" src={logotype}/>
            </div>
          </div>

            <div className="gx-app-login-content">
              {
                step !== 1 &&
                <Form
                  name="basic"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  className="gx-signin-form gx-form-row0">

                  <Form.Item
                    initialValue=""
                    rules={[{required: true, message: <IntlMessages id="validation.required"/>}]} name="email">
                    <Input placeholder="Adres e-mail"/>
                  </Form.Item>
                  <Form.Item
                    initialValue=""
                    rules={[{required: true, message: <IntlMessages id="validation.required"/>}]} name="password">
                    <Input type="password" placeholder="Hasło"/>
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" className="gx-mb-0" htmlType="submit">
                      <IntlMessages id="app.userAuth.signIn"/>
                    </Button>
                  </Form.Item>
                </Form>
              }
              {
                step === 1 &&
                <Form
                  name="basic"
                  onFinish={onFinishTwoFactor}
                  onFinishFailed={onFinishTwoFactorFailed}
                  className="gx-signin-form gx-form-row0">

                  <Form.Item
                    initialValue=""
                    rules={[{required: true, message: <IntlMessages id="validation.required"/>}]} name="token">
                    <Input placeholder="Podaj kod weryfikacyjny"/>
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" className="gx-mb-0" htmlType="submit">
                      <IntlMessages id="app.userAuth.signIn"/>
                    </Button>
                  </Form.Item>
                </Form>
              }
              <InfoView />
            </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
