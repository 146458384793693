import {
  INIT_URL,
  SIGNIN_TWO_FACTOR,
  OPT_OUT_STEP,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET
} from "../../constants/ActionTypes";

const INIT_STATE = {
  two_factor_step: 0,
  opt_out_step: 0,
  token: JSON.parse(localStorage.getItem('token')),
  initURL: '',
  authUser: JSON.parse(localStorage.getItem('user')),
};

let Auth = (state = INIT_STATE, action) => {
  switch (action.type) {

    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      };
    }

    case SIGNIN_TWO_FACTOR: {
      return {
        ...state,
        two_factor_step: action.two_factor_step
      };
    }

    case OPT_OUT_STEP: {
      return {
        ...state,
        opt_out_step: action.opt_out_step
      };
    }

    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        token: null,
        authUser: null,
        initURL: ''
      }
    }

    case USER_DATA: {
      return {
        ...state,
        authUser: action.payload,
      };
    }

    case USER_TOKEN_SET: {
      return {
        ...state,
        token: action.payload,
      };
    }

    default:
      return state;
  }
};

export default Auth;
