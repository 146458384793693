import React, {useContext, useEffect, useState} from "react";
import NotificationItem from "./NotificationItem";
import CustomScrollbars from "util/CustomScrollbars";
import Auxiliary from "util/Auxiliary";
import IntlMessages from "../../util/IntlMessages";
import fetchChangeLogs from "../../models/ChangeLog/api";
import {ReactReduxContext} from 'react-redux'
import ChangeLogPayload from "../../models/ChangeLog/payload";
import {fetchErrorMessage} from "../../appRedux/actions";


const AppNotification = () => {
  const [notifications, setNotifications] = useState([]);
  const {store} = useContext(ReactReduxContext);

  useEffect(() => {

    fetchChangeLogs(
      store,
      (new ChangeLogPayload(1, 5, ''))
    ).then(({objects}) => setNotifications(objects))
      .catch((error) => {
        store.dispatch(fetchErrorMessage(error));
      });
  }, notifications); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {notifications && <Auxiliary>
        <div className="gx-popover-header">
          <h3 className="gx-mb-0"><IntlMessages id="sidebar.notification"/></h3>
          <i className="gx-icon-btn icon icon-charvlet-down"/>
        </div>
        <CustomScrollbars className="gx-popover-scroll">
          <ul className="gx-sub-popover">
            {notifications.map((notification, index) => <NotificationItem key={index}
                                                                          notification={notification}/>)
            }
          </ul>
        </CustomScrollbars>
      </Auxiliary>}
    </>
  )
};

export default AppNotification;

