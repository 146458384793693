class ChangeLog {
  item = {};
  fields = [
    "id",
    "status",
    "type",
    "value",
    "marketingConsentName",
    "marketingConsentSlug",
    "marketingConsentValue",
    "activeMarketingConsent",
    "dateLastChangeMarketingConsent",
    "createdAt",
    "integration",
  ];

  constructor(item) {
    this.fields.map((field) => {
      this.item[field] = item[field];
      return field;
    });
  }

  getObject() {
    return this.item;
  }

  getKey() {
    return this.item.id;
  }

  getFieldKey(field) {
    return this.getKey() + '-' + field;
  }

  getField(field) {
    return this.item[field];
  }

}

export default ChangeLog;
