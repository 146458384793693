export default class ChangeLogPayload {
  page = 1;
  limit = 10;
  search = '';

  constructor(page, limit, search) {
    this.page = page;
    this.limit = limit;
    this.search = search;
  }

  getPayload() {
    return {
      "page": this.page,
      "limit": this.limit,
      "search": this.search,
    }
  }
}
