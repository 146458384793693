import React from "react";
import {Route, Switch} from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const App = ({match}) => {
  return (
    <div className="gx-main-content-wrapper">
      <Switch>
        <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./Dashboard'))}/>

        <Route path={`${match.url}consents/show/:id`} component={asyncComponent(() => import('./Consent/Show'))}/>
        <Route path={`${match.url}consents/delete/:id`} component={asyncComponent(() => import('./Consent/Delete'))}/>
        <Route path={`${match.url}consents/search/:search?/:page?`}
               component={asyncComponent(() => import('./Consent/Search'))}/>
        <Route path={`${match.url}consents/:page?`} component={asyncComponent(() => import('./Consent'))}/>

        <Route path={`${match.url}clients/add/`} component={asyncComponent(() => import('./Clients/Add'))}/>
        <Route path={`${match.url}clients/show/:id`} component={asyncComponent(() => import('./Clients/Show'))}/>
        <Route path={`${match.url}clients/edit/:id`} component={asyncComponent(() => import('./Clients/Edit'))}/>
        <Route path={`${match.url}clients/consent/:id`} component={asyncComponent(() => import('./Clients/Consent'))}/>
        <Route path={`${match.url}clients/delete/:id`} component={asyncComponent(() => import('./Clients/Delete'))}/>
        <Route path={`${match.url}clients/search/:search?/:page?`}
               component={asyncComponent(() => import('./Clients/Search'))}/>
        <Route path={`${match.url}clients/:page?`} component={asyncComponent(() => import('./Clients'))}/>

        <Route path={`${match.url}consent-types/add/`} component={asyncComponent(() => import('./ConsentTypes/Add'))}/>
        <Route path={`${match.url}consent-types/show/:id`}
               component={asyncComponent(() => import('./ConsentTypes/Show'))}/>
        <Route path={`${match.url}consent-types/edit/:id`}
               component={asyncComponent(() => import('./ConsentTypes/Edit'))}/>
        <Route path={`${match.url}consent-types/delete/:id`}
               component={asyncComponent(() => import('./ConsentTypes/Delete'))}/>
        <Route path={`${match.url}consent-types/:page?`} component={asyncComponent(() => import('./ConsentTypes'))}/>

        <Route path={`${match.url}users/add/`} component={asyncComponent(() => import('./Users/Add'))}/>
        <Route path={`${match.url}users/show/:id`} component={asyncComponent(() => import('./Users/Show'))}/>
        <Route path={`${match.url}users/edit/:id`} component={asyncComponent(() => import('./Users/Edit'))}/>
        <Route path={`${match.url}users/delete/:id`} component={asyncComponent(() => import('./Users/Delete'))}/>
        <Route path={`${match.url}users/change_password/`}
               component={asyncComponent(() => import('./Users/ChangePassword'))}/>
        <Route path={`${match.url}users/:page?`} component={asyncComponent(() => import('./Users'))}/>

        <Route path={`${match.url}external-integration/add/`}
               component={asyncComponent(() => import('./ExternalIntegration/Add'))}/>
        <Route path={`${match.url}external-integration/show/:id`}
               component={asyncComponent(() => import('./ExternalIntegration/Show'))}/>
        <Route path={`${match.url}external-integration/token/:id`}
               component={asyncComponent(() => import('./ExternalIntegration/Token'))}/>
        <Route path={`${match.url}external-integration/delete/:id`}
               component={asyncComponent(() => import('./ExternalIntegration/Delete'))}/>
        <Route path={`${match.url}external-integration/:page?`}
               component={asyncComponent(() => import('./ExternalIntegration'))}/>

        <Route path={`${match.url}logs/show/:id`} component={asyncComponent(() => import('./Logs/Show'))}/>
        <Route path={`${match.url}logs/:page?`} component={asyncComponent(() => import('./Logs'))}/>
      </Switch>
    </div>
  )
};

export default App;
