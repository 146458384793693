import React from 'react';
import {clearNotification} from "../../../appRedux/actions";

class InlineNotify extends React.Component {
  clock = null;

  static defaultProps = {
    type: 'info', // 'info', 'success', 'warning', 'error'
    message: null,
    timeout: 3000,
    onClick: () => {}
  };

  constructor(props) {
    super(props);
    this.state = {
      message: '',
      validationErrors: null
    };
  }

  componentWillReceiveProps(nextProps) {
    this.checkProps(nextProps);
  }

  checkProps(props) {
    clearTimeout(this.clock);
    this.hideMe();

    console.log(this.props);

    if (typeof this.props.message === 'string') {
      this.setState({
        message: this.props.message,
        validationErrors: null
      });
      return true;
    }

    if (typeof this.props.message.errors.message === 'string') {
      this.setState({
        message: this.props.message.errors.message,
        validationErrors: null
      });
      return true;
    }

    if (Array.isArray(this.props.message.errors.message)) {
      let validationErrors = [];

      this.props.message.errors.message.map((item) => {
        validationErrors.push({
          field: item.field,
          value: '"' + item.value + '" - ' + item.message
        });
        return item;
      });

      this.setState({
        message: 'Proszę poprawić błędy formularza:',
        validationErrors: validationErrors
      });

      return true;
    }

    if (this.props.message.errors) {
      let validationErrors = [];
      if (this.props.message.errors.message.value) {
        validationErrors.push({
          field: this.props.message.errors.message.field,
          value: '"' + this.props.message.errors.message.value + '" - ' + this.props.message.errors.message.message
        });
      } else {
        for (const [key, value] of Object.entries(this.props.message.errors.message)) {
          validationErrors.push({field: key, value: value});
        }
      }

      this.setState({
        message: 'Proszę poprawić błędy formularza:',
        validationErrors: validationErrors
      });

      return true;
    }

    return false;
  }

  componentDidMount() {
    this.checkProps(this.props);
    this.hideMe();
  }

  hideMe() {
    this.clock = setTimeout(() => {
      this.props.store.dispatch(clearNotification());
    }, this.props.timeout);
  }

  handleClick = () => {
    const {onClick} = this.props;
    if (onClick) {
      onClick();
    }
  };

  render() {
    const {type} = this.props;
    const classNames = 'notification gx-mb-3 notification-' + type;

    return (
      <div className={classNames} onClick={this.handleClick}>
        <div className="notification-message" role="alert">
          <div className="message">
            {this.state.message}
            {this.state.validationErrors && (
              <>
                <br/><br/>
                <ul>
                  {this.state.validationErrors.map(function (item) {
                    return (<li key={"validation" + item.field + item.value}>{item.field} -{item.value}</li>);
                  })}
                </ul>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default InlineNotify;
