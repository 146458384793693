import ChangeLog from "./model";

class ChangeLogCollection {
  items = [];
  itemsObjects = [];
  totalItems = 0;
  totalPages = 0;

  constructor(result) {
    if (result.data.items === undefined) {
      this.items = [];
    } else {
      this.items = result.data.items;
      this.totalItems = result.data.totalItems;
      this.totalPages = result.data.pageCount;
    }
  }

  getItems() {
    this.itemsObjects = [];

    this.items.map((item) => {
      this.itemsObjects.push(new ChangeLog(item));
      return item;
    });

    return this.itemsObjects;
  }

  getTotalPages() {
    return this.totalPages;
  }

  getTotalItems() {
    return this.totalItems;
  }

}

export default ChangeLogCollection;
