import React from "react";
import {Menu} from "antd";
import {Link} from "react-router-dom";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import {
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import {useSelector} from "react-redux";

const SidebarContent = ({sidebarCollapsed, setSidebarCollapsed}) => {
  let {themeType} = useSelector(({settings}) => settings);
  let {pathname} = useSelector(({common}) => common);

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];
  return (
    <>
      <SidebarLogo sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed}/>
      <div className="gx-sidebar-content">
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline">

            <Menu.Item key="dashboard">
              <Link to="/dashboard"><i className="icon icon-stats"/>
                <span><IntlMessages id="sidebar.dashboard"/></span>
              </Link>
            </Menu.Item>

            <Menu.Item key="clients">
              <Link to="/clients"><i className="icon icon-team"/>
                <span><IntlMessages id="menu.clients"/></span>
              </Link>
            </Menu.Item>

            <Menu.Item key="consents">
              <Link to="/consents"><i className="icon icon-list-select-o"/>
                <span><IntlMessages id="menu.consents"/></span>
              </Link>
            </Menu.Item>

            <Menu.Item key="consent-types">
              <Link to="/consent-types"><i className="icon icon-cards-list-view"/>
                <span><IntlMessages id="menu.consentTypes"/></span>
              </Link>
            </Menu.Item>

            <Menu.Item key="users">
              <Link to="/users"><i className="icon icon-user"/>
                <span><IntlMessages id="menu.users"/></span>
              </Link>
            </Menu.Item>

            <Menu.Item key="external-integration">
              <Link to="/external-integration"><i className="icon icon-extra-components"/>
                <span><IntlMessages id="menu.external_integration"/></span>
              </Link>
            </Menu.Item>

            <Menu.Item key="logs">
              <Link to="/logs"><i className="icon icon-collapse"/>
                <span><IntlMessages id="menu.logs"/></span>
              </Link>
            </Menu.Item>


          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;

