import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "../../appRedux/actions/Auth";
import avatar from "../../assets/images/placeholder.jpg";
import IntlMessages from "../../util/IntlMessages";
import {Link} from "react-router-dom";

const UserProfile = () => {
  const dispatch = useDispatch();
  const user = useSelector(({auth}) => auth.authUser);

  const userMenuOptions = (
    <ul className="gx-user-popover">
      {user &&
        <li><Link to={'/users/show/' + user.id}><IntlMessages id="menu.myAccount"/></Link></li>
      }
      <li onClick={() => dispatch(userSignOut())}><IntlMessages id="menu.logout"/></li>
    </ul>
  );

  if (user) {
    return (
      <div className="gx-flex-row gx-align-items-center gx-avatar-row">
        <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
          <Avatar src={avatar}
                  className="gx-size-40 gx-pointer gx-mr-3" alt=""/>
          <span className="gx-avatar-name gx-fs-md">{user.username}<i
            className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/></span>
        </Popover>
      </div>
    )
  }
  return null;
};

export default UserProfile;
