import enLang from "./entries/en-US";
import plLang from "./entries/pl-PL";


const AppLocale = {
  pl: plLang,
  en: enLang
};

export default AppLocale;
