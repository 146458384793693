class ChangeLogSchema {
  static apiEndpoint = "/api/change-logs";
  static schema = {
    header: [
      "Data",
      "Status",
      "Treść",
      "Wartość",
      "Akcja"
    ],
    row: [
      {type: "data", map: "createdAt"},
      {type: "data", map: "status"},
      {type: "data", map: "marketingConsentValue"},
      {type: "data-string", map: "activeMarketingConsent"},
      {type: "externalContent"}
    ],
    actions: [
      {url: '/logs/show/', label: "Zobacz"}
    ],
    pagination: {
      url: "/logs/",
      search: "/logs/search/#PHRASE#/"
    },
    fields: {
      "id": {
        label: "ID",
        place: ["show"],
        mode: "non-editable",
        type: "text"
      },
      "status": {
        label: "Status",
        place: ["show"],
        mode: "non-editable",
        type: "text"
      },
      "type": {
        label: "Typ",
        place: ["show"],
        mode: "non-editable",
        type: "text"
      },
      "value": {
        label: "Wartość",
        place: ["show"],
        mode: "non-editable",
        type: "text"
      },
      "marketingConsentName": {
        label: "Nazwa zgody",
        place: ["show"],
        mode: "non-editable",
        type: "text"
      },
      "marketingConsentSlug": {
        label: "Slug zgody",
        place: ["show"],
        mode: "non-editable",
        type: "text"
      },
      "marketingConsentValue": {
        label: "Wartość zgody",
        place: ["show"],
        mode: "non-editable",
        type: "text"
      },
      "activeMarketingConsent": {
        label: "Zgoda marketingowa",
        place: ["show"],
        mode: "non-editable",
        type: "text"
      },
      "dateLastChangeMarketingConsent": {
        label: "Ostatnia zmiany zgody marketingowej",
        place: ["show"],
        mode: "non-editable",
        type: "text"
      },
      "createdAt": {
        label: "Data utworzenia",
        place: ["show"],
        mode: "non-editable",
        type: "text"
      },
      "integration": {
        label: "Integracja",
        place: ["show"],
        mode: "non-editable",
        type: "text"
      },

    }
  };
}

export default ChangeLogSchema;
